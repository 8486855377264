
import {
  defineComponent,
  reactive,
  toRefs,
  onMounted,
  computed,
  ref,
} from "vue";
import { Api } from "@/assets/Api";
import { ElMessage, ElMessageBox } from "element-plus";
import store from '@/store'
//import RegisterTime from "./RegisterTime.vue";

interface Column {
  label: string;
  prop: string;
  search: boolean;
  regex: boolean;
  rules: [];
}
export default defineComponent({
  name: "ConfigTouSu",
  props: ["dialogFormVisible"],
  emits: ["close"],
  components: {},
  setup(props, { emit }) {
    let data = reactive<any>({
      init: false,
      maps: new Map(),
      data: {},
      option: {},
      page: {
        total: 0,
        currentPage: 1,
        pageSize: 10,
        pageSizes: [10, 20],
        layout: "total, sizes, prev, pager, next",
      },
      title: "",
      complaintFormList:[],
      ComplaintFormLogsList:[],
      userList:[],
      row: [],
      fileList: [],
      phone:"",
      dialogFormVisible2: false,
      dialogFormVisible3: false,
      dialogFormVisible4: false,
      dialogFormVisible5: false,
      OperateValue: 0,
      OperateName:"",
      tousuForm:{
        ComplaintFormId:"",
        Phone:"",
        PackagesName:"",
        PackagesFee:"",
        StartDate:"",
        EndDate:"",
        ContactPhone:"",
        Status:"",
        Results:"",
        Memo:"",
      },
      addForm: {
        Phone: "",
        PackagesId: 0,
        ContactPhone: "",
        Memo: "",
      },
      editForm: {
        ComplaintFormId: 0,
        ContactPhone: "",
        Memo: "",
      },
      handleForm:{
        ComplaintFormId: 0,
        Operate: "",
        Info: "",
        Memo: "",
      },
      headers: {
        Authorization: ""
      },
      iOperate: 0,
      url: "",

      options: [
        { label: '退订业务', value: '2' },
        { label: '回访', value: '3' },
        { label: '充值退费', value: '4' },
        { label: '当月退费', value: '5' },
        { label: '上传退费凭证', value: '6' },
        { label: '完成投诉单', value: '7' },
        { label: '直接退费', value: '8' },
      ]
    });
    const handlePreview = (file: any, fileList: any) =>{
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            ElMessageBox.alert("上传图片大小不能超过 2MB!");
            data.fileList = [];
            return;
        }
        
        // console.log("file");
        // console.log(store.getters.token);
      //   data.fileList = fileList;
    }
    const handleSuccess = (response: any, file: any, fileList: any) =>{
      // 成功处理逻辑
      console.log(response);
      data.handleForm.Info = response.Data.url;
      
      Api.Msg.success("图片上传成功");
      data.fileList = [];
    }
    const handleError = (err: any, file: any, fileList: any) =>{
      // 错误处理逻辑
      ElMessageBox.alert("图片上传失败，失败原因：" + err + "，请重新上传！");
    }

    const handleRemove = (file: any, fileList: any) => {
        console.log(file, fileList);
    };

    const addTousu = (row: any) => { 
        data.dialogFormVisible2 = true;        
        data.addForm.Phone = data.tousuForm.Phone;
        data.addForm.PackagesId = data.tousuForm.PackagesId;
        // console.log("row");
        // console.log(row);
    };

    const TouSuAdd = () => { 
        data.dialogFormVisible2 = false;
        // console.log("data.addForm");
        // console.log(data.addForm);
        Api.Config.addTouSu(data.addForm).then((res: any) => {
        
        console.log(res);
        if (res.Code == 0) {  
            Api.Msg.success("投诉单已成功添加");
            getComplaintForm();
        } 
      });
    };

    const editTousu = () => {        
        data.dialogFormVisible3 = true;        
        data.editForm.ContactPhone = data.tousuForm.ContactPhone;
        data.editForm.ComplaintFormId = data.tousuForm.ComplaintFormId;
        data.editForm.Memo = data.tousuForm.Memo;
        // console.log("row");
        // console.log(data.editForm);
    };

    const TousuEdit = () => { 
        // data.dialogFormVisible3 = false;
        // console.log("data.editForm");
        // console.log(data.editForm);
        ElMessageBox.confirm("确定要修改投诉单信息吗？", "通知", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "",
      })
        .then(() => {    
            data.editForm.ContactPhone = data.tousuForm.ContactPhone;
            data.editForm.ComplaintFormId = data.tousuForm.ComplaintFormId;
            data.editForm.Memo = data.tousuForm.Memo;
            Api.Config.editTouSu(data.editForm).then((res: any) => {
            if (res.Code == 0) {  
                Api.Msg.success("投诉单已成功修改");
                getComplaintForm();
            }
            else{
              ElMessageBox.alert(res.ResultMsg,'失败');
              return;
            } 
          });
          data.editForm.ContactPhone = "";
          data.editForm.Memo = "";
        })
        .catch(() => {
          console.log("修改失败");
        });
        
    };

    const handleTousu = () => { 
      data.fileList = [];
        data.handleForm.ComplaintFormId = data.tousuForm.ComplaintFormId;   
        data.handleForm.Operate = "";
        data.handleForm.Info = data.tousuForm.ExpectedFee;
        data.handleForm.Memo = "";    
        data.dialogFormVisible4 = true;
    };

    const selectOperateChange = (item: any) => {
      // console.log("label");
      // console.log(item);
        data.OperateValue = parseInt(item.value);
        data.OperateName = item.label;
        console.log(data.OperateName);
        
    };

    const dialogVisible: any = computed({
      get() {
        return props.dialogFormVisible;
      },
      set() {
        emit("close");
      },
    });

    const searchChange = (row: any) => {
        // console.log("row");
        // console.log(row);
        data.row = row;
        getComplaintForm();
    };

    const changePage = ({ pageSize, currentPage }: any) => {
      data.query.page = currentPage;
      data.query.limit = pageSize;
      getComplaintForm();
    };

    const getComplaintForm = () => {
      //console.log(data.query);
      data.complaintFormList = [];
      data.ComplaintFormLogsList = [];
      let tousudata = {phone: data.row.phone, packagesId: data.row.packagesId}
      // console.log("tousudata");
      // console.log(tousudata);
      Api.Config.GetTouSuList(tousudata).then((res: any) => {
        // console.log(res);
        
        if (res.Code == 0) {  
            res.Data.StartDate = res.Data.StartDate.replace(/T/g, ' ').replace(/.[\d]{3}Z/, ' ');
            res.Data.EndDate = res.Data.EndDate.replace(/T/g, ' ').replace(/.[\d]{3}Z/, ' ');
            res.Data.CreatedAt = res.Data.CreatedAt.replace(/T/g, ' ').replace(/.[\d]{3}Z/, ' ');
            res.Data.PackagesFee = res.Data.PackagesFee / 100;
            res.Data.ExpectedFee = res.Data.ExpectedFee / 100;
            if(res.Data.Status == true){
                res.Data.Status = "完成"
            }
            else{
                res.Data.Status = "处理中"
            }

            if(res.Data.Results == 0){
                res.Data.Results = "未处理"
            }
            else if(res.Data.Results == 1){
                res.Data.Results = "退订"
            }
            else{
                res.Data.Results = "退费"
            }
            data.tousuForm = res.Data
            
            // console.log("data.tousuForm");
            // console.log(data.tousuForm);
            let logsData = {ComplaintFormId: parseInt(data.tousuForm.ComplaintFormId)}
          Api.Config.GetComplaintFormLogsList(logsData).then((res: any) => {
            console.log("res");
            console.log(res);
            
            if (res.Code == 0) {  
                for(var i = 0; i < res.Data.length; i++){
                    res.Data[i].CreatedAt = res.Data[i].CreatedAt.replace(/T/g, ' ').replace(/.[\d]{3}Z/, ' ');
                    switch (res.Data[i].Operate) {
                        case 0:
                            res.Data[i].Operate = "新增投诉单";
                            res.Data[i].iOperate = 0
                        break;
                        case 1:
                            res.Data[i].Operate = "修改信息";
                            res.Data[i].iOperate = 1
                        break;
                        case 2:
                            res.Data[i].Operate = "退订业务";
                            res.Data[i].iOperate = 2
                        break;
                        case 3:
                            res.Data[i].Operate = "回访";
                            res.Data[i].iOperate = 3
                        break;
                        case 4:
                            res.Data[i].Operate = "充值退费";
                            res.Data[i].Operate2 = "退费金额：" + (res.Data[i].Information / 100) + "元";
                            res.Data[i].iOperate = 4
                        break;
                        case 5:
                            res.Data[i].Operate = "当月退费";
                            res.Data[i].Operate2 = "退费金额：" + (res.Data[i].Information / 100) + "元";
                            res.Data[i].iOperate = 5
                        break;
                        case 6:
                            res.Data[i].Operate = "上传退费凭证";
                            res.Data[i].iOperate = 6
                        break;
                        case 7:
                            res.Data[i].Operate = "完成投诉单";
                            res.Data[i].iOperate = 7
                        break;
                        case 8:
                            res.Data[i].Operate = "直接退费";
                            res.Data[i].Operate2 = "退费金额：" + (res.Data[i].Information / 100) + "元";
                            res.Data[i].iOperate = 8
                        break;
                        }
                    data.ComplaintFormLogsList.push(res.Data[i])
                }
                // console.log("data.ComplaintFormLogsList");
                // console.log(data.ComplaintFormLogsList);
            } 
          });
        } 
      });

      
      
    };

    const submitOperate = () => { 
        data.handleForm.ComplaintFormId = parseInt(data.handleForm.ComplaintFormId);
        data.handleForm.Operate = parseInt(data.OperateValue);
        // console.log(data.handleForm);
        if(data.handleForm.Operate == 6 && data.handleForm.Info == ""){
          data.OperateValue = 6;
          data.handleForm.Operate = "";
          ElMessageBox.alert("请先上传凭证");
          return;
        }
        if(data.handleForm.Operate == 4 || data.handleForm.Operate == 8){
          if(data.handleForm.Info == ""){
            data.OperateValue = data.handleForm.Operate;
            data.handleForm.Operate = "";            
            ElMessageBox.alert("请输入充值金额");
            return;
          }
          data.handleForm.Info = (data.handleForm.Info * 100) + "";
        }
        if(data.handleForm.Operate != 4 && data.handleForm.Operate != 6  && data.handleForm.Operate != 8){
          
          data.handleForm.Info = "";
        }
        // console.log("data.handleForm");
        // console.log(data.handleForm);
        Api.Config.handleTousu(data.handleForm).then((res: any) => {
          // console.log("res");
        console.log(res);
        if (res.Code == 0) {  
            Api.Msg.success("投诉单处理完成");
            getComplaintForm();
        }
        else{
              ElMessageBox.alert(res.ResultMsg,'失败');
              return;
        }  
      });
        data.OperateValue = 0;
        data.OperateName = "";
        data.dialogFormVisible4 = false;
        data.handleForm.Operate = "";
        data.handleForm.Info = "";
        data.handleForm.Memo = "";
      
    };
    const viewRefundVoucher = (row: any) => {
      let url = "https://a.xjjkzs.com/" + row.Information;
      // console.log("row");
      // console.log(row);
      data.url = url;
      data.dialogFormVisible5 = true;
    }
    onMounted(() => {
      data.headers.Authorization = "Bearer " + store.getters.token;
    });

    return {
      ...toRefs(data),
      dialogVisible,
      getComplaintForm,
      changePage,
      searchChange,
      addTousu,
      TouSuAdd,
      editTousu,
      handleTousu,
      TousuEdit,
      selectOperateChange,
      handleRemove,
      handlePreview,
      handleSuccess,
      handleError,
      submitOperate,
      viewRefundVoucher,
    };
  },
});
