import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createBlock as _createBlock, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-67d7bbda"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "sketch_content" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "demo-image__lazy" }
const _hoisted_4 = { class: "dialog-footer" }
const _hoisted_5 = { class: "dialog-footer" }
const _hoisted_6 = { class: "dialog-footer" }
const _hoisted_7 = { class: "footer-btn" }
const _hoisted_8 = { key: 0 }
const _hoisted_9 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_table = _resolveComponent("el-table")!
  const _component_el_image = _resolveComponent("el-image")!
  const _component_el_dialog = _resolveComponent("el-dialog")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_upload = _resolveComponent("el-upload")!

  return (_openBlock(), _createBlock(_component_el_dialog, {
    title: "投诉处理",
    class: "el-dialog-div",
    modelValue: _ctx.dialogVisible,
    "onUpdate:modelValue": _cache[28] || (_cache[28] = ($event: any) => ((_ctx.dialogVisible) = $event)),
    width: "99%",
    height: "50%",
    onClose: _cache[29] || (_cache[29] = ($event: any) => (_ctx.$emit('close')))
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_el_form, { model: _ctx.tousuForm }, {
          default: _withCtx(() => [
            _createVNode(_component_el_form_item, {
              label: "用户号码",
              "label-width": "120px"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  modelValue: _ctx.tousuForm.Phone,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.tousuForm.Phone) = $event)),
                  disabled: "",
                  autocomplete: "off"
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }),
            _createVNode(_component_el_form_item, {
              label: "套餐名称",
              "label-width": "120px"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  modelValue: _ctx.tousuForm.PackagesName,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.tousuForm.PackagesName) = $event)),
                  disabled: "",
                  autocomplete: "off"
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }),
            _createVNode(_component_el_form_item, {
              label: "套餐费用（元）",
              "label-width": "120px"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  modelValue: _ctx.tousuForm.PackagesFee,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.tousuForm.PackagesFee) = $event)),
                  disabled: "",
                  autocomplete: "off"
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }),
            _createVNode(_component_el_form_item, {
              label: "订购时间",
              "label-width": "120px"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  modelValue: _ctx.tousuForm.StartDate,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.tousuForm.StartDate) = $event)),
                  disabled: "",
                  autocomplete: "off"
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }),
            _createVNode(_component_el_form_item, {
              label: "退订时间",
              "label-width": "120px"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  modelValue: _ctx.tousuForm.EndDate,
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.tousuForm.EndDate) = $event)),
                  disabled: "",
                  autocomplete: "off"
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }),
            _createVNode(_component_el_form_item, {
              label: "联系电话",
              "label-width": "120px"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  modelValue: _ctx.tousuForm.ContactPhone,
                  "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.tousuForm.ContactPhone) = $event)),
                  autocomplete: "off"
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }),
            _createVNode(_component_el_form_item, {
              label: "投诉单状态",
              "label-width": "120px"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  modelValue: _ctx.tousuForm.Status,
                  "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.tousuForm.Status) = $event)),
                  disabled: "",
                  autocomplete: "off"
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }),
            _createVNode(_component_el_form_item, {
              label: "处理结果",
              "label-width": "120px"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  modelValue: _ctx.tousuForm.Results,
                  "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.tousuForm.Results) = $event)),
                  disabled: "",
                  autocomplete: "off"
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }),
            _createVNode(_component_el_form_item, {
              label: "备注",
              "label-width": "120px"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  modelValue: _ctx.tousuForm.Memo,
                  "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.tousuForm.Memo) = $event)),
                  autocomplete: "off"
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["model"]),
        _createVNode(_component_el_table, {
          ref: "table",
          data: _ctx.ComplaintFormLogsList,
          border: "",
          style: {"width":"100%"},
          height: "40vh",
          "row-key": "complaintFormId",
          stripe: "",
          "header-cell-style": {'text-align':'center'},
          "cell-style": {'text-align':'center'}
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_table_column, {
              prop: "Operator",
              label: "操作人"
            }),
            _createVNode(_component_el_table_column, {
              prop: "CreatedAt",
              label: "提单时间"
            }),
            _createVNode(_component_el_table_column, {
              prop: "Operate",
              label: "所做操作"
            }),
            _createVNode(_component_el_table_column, {
              prop: "Operate2",
              label: "操作信息"
            }, {
              default: _withCtx((scope) => [
                (scope.row.iOperate == 4 || scope.row.iOperate == 5 || scope.row.iOperate == 8)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(scope.row.Operate2), 1))
                  : _createCommentVNode("", true),
                (scope.row.iOperate == 6)
                  ? (_openBlock(), _createBlock(_component_el_button, {
                      key: 1,
                      size: "small",
                      type: "primary",
                      link: "",
                      onClick: ($event: any) => (_ctx.viewRefundVoucher(scope.row))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode("查看退费凭证")
                      ]),
                      _: 2
                    }, 1032, ["onClick"]))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }),
            _createVNode(_component_el_table_column, {
              prop: "Memo",
              label: "备注"
            })
          ]),
          _: 1
        }, 8, ["data"]),
        _createVNode(_component_el_dialog, {
          title: "查看退费凭证",
          modelValue: _ctx.dialogFormVisible5,
          "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.dialogFormVisible5) = $event))
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_el_image, { src: _ctx.url }, null, 8, ["src"])
            ])
          ]),
          _: 1
        }, 8, ["modelValue"]),
        _createVNode(_component_el_dialog, {
          title: "新增投诉单",
          modelValue: _ctx.dialogFormVisible2,
          "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.dialogFormVisible2) = $event))
        }, {
          footer: _withCtx(() => [
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_el_button, {
                onClick: _cache[13] || (_cache[13] = ($event: any) => (_ctx.dialogFormVisible2 = false))
              }, {
                default: _withCtx(() => [
                  _createTextVNode("取 消")
                ]),
                _: 1
              }),
              _createVNode(_component_el_button, {
                type: "primary",
                onClick: _ctx.TouSuAdd
              }, {
                default: _withCtx(() => [
                  _createTextVNode("确 定")
                ]),
                _: 1
              }, 8, ["onClick"])
            ])
          ]),
          default: _withCtx(() => [
            _createVNode(_component_el_form, { model: _ctx.addForm }, {
              default: _withCtx(() => [
                _createVNode(_component_el_form_item, {
                  label: "手机号码",
                  "label-width": "120px"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_input, {
                      modelValue: _ctx.addForm.Phone,
                      "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.addForm.Phone) = $event)),
                      disabled: "",
                      autocomplete: "off"
                    }, null, 8, ["modelValue"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_form_item, {
                  label: "联系电话",
                  "label-width": "120px"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_input, {
                      modelValue: _ctx.addForm.ContactPhone,
                      "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.addForm.ContactPhone) = $event)),
                      autocomplete: "off"
                    }, null, 8, ["modelValue"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_form_item, {
                  label: "备注",
                  "label-width": "120px"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_input, {
                      modelValue: _ctx.addForm.Memo,
                      "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.addForm.Memo) = $event)),
                      autocomplete: "off"
                    }, null, 8, ["modelValue"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["model"])
          ]),
          _: 1
        }, 8, ["modelValue"]),
        _createVNode(_component_el_dialog, {
          title: "修改投诉单",
          modelValue: _ctx.dialogFormVisible3,
          "onUpdate:modelValue": _cache[18] || (_cache[18] = ($event: any) => ((_ctx.dialogFormVisible3) = $event))
        }, {
          footer: _withCtx(() => [
            _createElementVNode("div", _hoisted_5, [
              _createVNode(_component_el_button, {
                onClick: _cache[17] || (_cache[17] = ($event: any) => (_ctx.dialogFormVisible3 = false))
              }, {
                default: _withCtx(() => [
                  _createTextVNode("取 消")
                ]),
                _: 1
              }),
              _createVNode(_component_el_button, {
                type: "primary",
                onClick: _ctx.TousuEdit
              }, {
                default: _withCtx(() => [
                  _createTextVNode("确 定")
                ]),
                _: 1
              }, 8, ["onClick"])
            ])
          ]),
          default: _withCtx(() => [
            _createVNode(_component_el_form, { model: _ctx.editForm }, {
              default: _withCtx(() => [
                _createVNode(_component_el_form_item, {
                  label: "联系电话",
                  "label-width": "120px"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_input, {
                      modelValue: _ctx.editForm.ContactPhone,
                      "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.editForm.ContactPhone) = $event)),
                      autocomplete: "off"
                    }, null, 8, ["modelValue"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_form_item, {
                  label: "备注",
                  "label-width": "120px"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_input, {
                      modelValue: _ctx.editForm.Memo,
                      "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.editForm.Memo) = $event)),
                      autocomplete: "off"
                    }, null, 8, ["modelValue"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["model"])
          ]),
          _: 1
        }, 8, ["modelValue"]),
        _createVNode(_component_el_dialog, {
          title: "处理投诉单",
          modelValue: _ctx.dialogFormVisible4,
          "onUpdate:modelValue": _cache[24] || (_cache[24] = ($event: any) => ((_ctx.dialogFormVisible4) = $event))
        }, {
          footer: _withCtx(() => [
            _createElementVNode("div", _hoisted_6, [
              _createVNode(_component_el_button, {
                onClick: _cache[23] || (_cache[23] = ($event: any) => (_ctx.dialogFormVisible4 = false))
              }, {
                default: _withCtx(() => [
                  _createTextVNode("取 消")
                ]),
                _: 1
              }),
              _createVNode(_component_el_button, {
                type: "primary",
                onClick: _ctx.submitOperate
              }, {
                default: _withCtx(() => [
                  _createTextVNode("确 定")
                ]),
                _: 1
              }, 8, ["onClick"])
            ])
          ]),
          default: _withCtx(() => [
            _createVNode(_component_el_form, { model: _ctx.handleForm }, {
              default: _withCtx(() => [
                _createVNode(_component_el_form_item, {
                  label: "操作",
                  "label-width": "120px"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_select, {
                      modelValue: _ctx.OperateName,
                      "onUpdate:modelValue": _cache[19] || (_cache[19] = ($event: any) => ((_ctx.OperateName) = $event)),
                      placeholder: "请选择操作类型",
                      onChange: _ctx.selectOperateChange,
                      clearable: ""
                    }, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (item) => {
                          return (_openBlock(), _createBlock(_component_el_option, {
                            key: item.value,
                            label: item.label,
                            value: item
                          }, null, 8, ["label", "value"]))
                        }), 128))
                      ]),
                      _: 1
                    }, 8, ["modelValue", "onChange"])
                  ]),
                  _: 1
                }),
                (_ctx.OperateValue == 6)
                  ? (_openBlock(), _createBlock(_component_el_form_item, {
                      key: 0,
                      label: "上传图片",
                      "label-width": "120px"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_upload, {
                          class: "upload-demo",
                          ref: "upload",
                          action: "/api/admin/config/upload/complaintForm",
                          "list-type": "picture",
                          "on-change": _ctx.handlePreview,
                          "on-remove": _ctx.handleRemove,
                          headers: _ctx.headers,
                          "on-success": _ctx.handleSuccess,
                          "on-error": _ctx.handleError,
                          "file-list": _ctx.fileList,
                          accept: ".png,.jpg,.jpeg,.gif",
                          limit: 1,
                          "auto-upload": false
                        }, {
                          trigger: _withCtx(() => [
                            _createVNode(_component_el_button, {
                              size: "small",
                              type: "primary"
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode("选取文件")
                              ]),
                              _: 1
                            })
                          ]),
                          default: _withCtx(() => [
                            _createVNode(_component_el_button, {
                              style: {"margin-left":"10px"},
                              size: "small",
                              type: "success",
                              onClick: _cache[20] || (_cache[20] = ($event: any) => (_ctx.$refs.upload.submit()))
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode("上传到服务器")
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }, 8, ["on-change", "on-remove", "headers", "on-success", "on-error", "file-list"])
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                (_ctx.OperateValue == 4 || _ctx.OperateValue == 8)
                  ? (_openBlock(), _createBlock(_component_el_form_item, {
                      key: 1,
                      label: "充值金额",
                      "label-width": "120px"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_input, {
                          modelValue: _ctx.handleForm.Info,
                          "onUpdate:modelValue": _cache[21] || (_cache[21] = ($event: any) => ((_ctx.handleForm.Info) = $event)),
                          autocomplete: "off"
                        }, null, 8, ["modelValue"])
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                _createVNode(_component_el_form_item, {
                  label: "备注",
                  "label-width": "120px"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_input, {
                      modelValue: _ctx.handleForm.Memo,
                      "onUpdate:modelValue": _cache[22] || (_cache[22] = ($event: any) => ((_ctx.handleForm.Memo) = $event)),
                      autocomplete: "off"
                    }, null, 8, ["modelValue"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["model"])
          ]),
          _: 1
        }, 8, ["modelValue"])
      ]),
      _createElementVNode("div", _hoisted_7, [
        (_ctx.tousuForm.ComplaintFormId == 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
              _createVNode(_component_el_button, {
                type: "primary",
                onClick: _cache[25] || (_cache[25] = ($event: any) => (_ctx.addTousu()))
              }, {
                default: _withCtx(() => [
                  _createTextVNode("新增")
                ]),
                _: 1
              })
            ]))
          : _createCommentVNode("", true),
        (_ctx.tousuForm.ComplaintFormId != 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
              _createVNode(_component_el_button, {
                type: "primary",
                onClick: _cache[26] || (_cache[26] = ($event: any) => (_ctx.TousuEdit()))
              }, {
                default: _withCtx(() => [
                  _createTextVNode("修改")
                ]),
                _: 1
              }),
              _createVNode(_component_el_button, {
                type: "primary",
                onClick: _cache[27] || (_cache[27] = ($event: any) => (_ctx.handleTousu()))
              }, {
                default: _withCtx(() => [
                  _createTextVNode("处理")
                ]),
                _: 1
              })
            ]))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }, 8, ["modelValue"]))
}